export const commonSelectRowMixin = {
  data() {
    return {
      selectedItems:[],
      lastCheckedIndex: null,
      selectRowItemKey: 'id'
    };
  },
  methods: {
      selectAllRows(e) {
        if(e.target.checked){
          this.selectedItems = this.pageData.data.map(item => item[this.selectRowItemKey]);
        }else{
          this.selectedItems = []
        }
      },
      handleCheckboxClick(event, item) {
          const currentIndex = this.pageData.data.findIndex(
            i => i[this.selectRowItemKey] === item[this.selectRowItemKey]
          );
          if (this.lastCheckedIndex !== null && event.shiftKey) {
            let start = Math.min(this.lastCheckedIndex, currentIndex);
            let end = Math.max(this.lastCheckedIndex, currentIndex);
  
            for (let i = start; i <= end; i++) {
              const itemId = this.pageData.data[i][this.selectRowItemKey];
              if (!this.selectedItems.includes(itemId)) {
                this.selectedItems.push(itemId);
              }
            }
          } else {
            this.lastCheckedIndex = currentIndex;
          }
      },
  },
};